import { Trans, useTranslation } from "react-i18next";
import { DefaultTFuncReturnWithObject } from "i18next";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import { TrackProgressTile } from "../../../common/components/TrackProgressTile/TrackProgressTile";
import "./TrackingProgressTile.scss";

type TextContent = {
  title: string;
  requirementLabel: string;
  eligibilityWindowDetails: string;
  met: string;
  notMet: string;
};
type Props = {
  totalRequirements: number;
  totalRequirementsMet: number;
  id?: string;
  isLoading: boolean;
  requirementMetStatuses: boolean[];
  clickAction?: Function;
  trackingContent?: DefaultTFuncReturnWithObject;
};

export default function TrackingProgressTile({
  isLoading,
  totalRequirements,
  totalRequirementsMet,
  id,
  requirementMetStatuses,
  clickAction,
  trackingContent,
}: Props) {
  const { t } = useTranslation();

  const contents = trackingContent as DefaultTFuncReturnWithObject as TextContent;

  const subtext = (
    <div className="TrackingProgressTile__subtext">
      <Trans>
        {t(contents.requirementLabel, {
          current: totalRequirementsMet,
          needed: totalRequirements,
        })}
        <span
          className={totalRequirements === totalRequirementsMet ? "Tracking__bold Tracking__met" : "Tracking__bold"}
        ></span>
      </Trans>
    </div>
  );

  const circlesSection = () => {
    return (
      <div className="TrackingProgressTile__icons">
        {requirementMetStatuses &&
          requirementMetStatuses.map((isReqMet, index) => (
            <div
              key={index}
              tabIndex={0}
              aria-label={
                isReqMet
                  ? `${t(contents?.met, { number: index + 1 })}`
                  : `${t(contents?.notMet, { number: index + 1 })}`
              }
            >
              <IconCheckCircle
                key={index}
                id={`TrackingProgressTile__icons-${index.toString()}`}
                color={isReqMet ? "green" : "gray"}
                size="lg"
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="TrackingProgressTile">
      <TrackProgressTile
        isLoading={isLoading}
        id={id}
        title={contents?.title}
        circlesSection={circlesSection()}
        subtext={subtext}
        buttonAreaLabel={contents?.eligibilityWindowDetails}
        buttonFunction={clickAction}
      />
    </div>
  );
}
