import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import partition from "lodash/partition";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import TitleSkeletonLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import defaultContent from "../../../content/newAboActivation";
import "./PathToBronze.scss";
import { storedLocale } from "../../impersonation/util";
import { ERROR_TYPE, ErrorPage } from "../../ErrorPage/ErrorPage";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import TEALIUM from "../../../common/enums/tealium-enums";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { BonusHist } from "../../../common/leaf/LeafBadgeGrid/LeafBadgeGrid";
import { VcsPercentRequirementTile } from "../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";
import { VolumeRequirementTile } from "../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import { LeafVolumeLabel } from "../../../common/leaf/LeafVolumeLabel/LeafVolumeLabel";
import { BronzeBannerNewPyToast } from "../BronzeBannerToast/NewPYBanner/NewPYBanner";
import LegRequirementTile from "./LegRequirementTile/LegRequirementTile";
import {
  getVolReqData,
  getTrackingReqData,
  isLatamMarketAndNotPastPeriod,
  getLegRequiredVolume,
  GetVolumeTypeLabel,
} from "./pathToBronzeUtils";
import TrackingRequirementTile from "./TrackingRequirementTile/TrackingRequirementTile";
import CalcDescTile from "./CalcDescriptionTile/CalcDescTile";
import EligibilityModal from "./EligibilityModal/EligibilityModal";
import { PtbBannerTile } from "./PtbBannerTile/PtbBannerTile";
import EligibilityTile from "./EligibilityTile/EligibilityTile";
import { FetchPtbData } from "./PathToBronzeDataLayer";

export default function PathToBronze() {
  const { t } = useTranslation(["newAboActivation"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { user } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const locale = storedLocale();

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const { data, isLoading, errorStatus } = FetchPtbData(selectedPeriod);

  const hasExceptionStatus = data.denied || data.held || data.manualApproval;

  const eligible = data.eligible;
  const ineligibleForPY = data.ineligibleForPY;
  const graduated = data.graduated;

  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  const volumeNeeded = data.legRequirementVolume.volumeRequired.formatted;
  const volumeTypeLabel = GetVolumeTypeLabel(data.legRequirementVolume.requirement);

  const { GPVTile, PPVTile, VCSTile, displayVCS } = getVolReqData(data);
  const trackingRequirementData = getTrackingReqData(data);

  const getHistory = () => {
    const [qualified, other] = partition(
      data.bonusHistory,
      (el) => el.status === QUALIFICATION_STATUS.QUALIFIED || el.status === QUALIFICATION_STATUS.TRACKING,
    );
    if (qualified.length >= data.awardCap.target || data.awardCap.remaining === 0) {
      // Once eligiblity met (graduated), set all other period status to un available
      return [
        ...qualified,
        ...other.map((el) => {
          return { status: QUALIFICATION_STATUS.UN_AVAILABLE, period: el.period };
        }),
      ] as BonusHist[];
    }

    // Set status as OPEN for all latam ABO's with NOT_QUALIFIED status
    return data.bonusHistory.map((el) =>
      el.status === QUALIFICATION_STATUS.NOT_QUALIFIED && isLatamMarketAndNotPastPeriod(el.period)
        ? { status: "OPEN", period: el.period }
        : el,
    ) as BonusHist[];
  };

  // hide tile if requirement is status is false
  const getVisibilityClass = (visible: boolean) => {
    return visible === false ? "--hidden" : "";
  };

  const getStatusClass = (status: boolean) => {
    return status === true ? " ptb__exception" : "";
  };

  // Will refactor these to a common util to be used by all pages in near future.
  const getErrorStatus = (): boolean => {
    if (data.showDateError) {
      return true;
    }
    return errorStatus;
  };

  const ReturnErrorComponent = () => {
    let errorType = ERROR_TYPE.GENERIC_ERROR;
    if (data.showDateError === true) {
      errorType = ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD;
    }

    return <ErrorPage errorType={errorType} isLoading={isLoading} />;
  };

  const handleViewEligibilityModalClick = () => {
    setShowModal(true);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PATH_TO_BRONZE,
      "",
      "view_eligibility",
    );
  };

  return (
    <main className="ptb">
      <BronzeBannerNewPyToast />
      <div className={`ptb__error ${!getErrorStatus() ? "ptb__hidden" : ""}`}>
        <ReturnErrorComponent />
      </div>
      <div hidden={getErrorStatus()}>
        <div className="ptb__overview">
          <div
            className={
              `ptb__overview__banner${data.held || data.denied ? "--restrictSize" : ""}` +
              (ineligibleForPY || graduated ? "--single" : "")
            }
          >
            <PtbBannerTile
              data={{ ...data, ...trackingRequirementData }}
              isLoading={isLoading}
              selectedPeriod={selectedPeriod}
              extraBonusesMet={trackingRequirementData.extraBonusesMet}
            ></PtbBannerTile>
          </div>
          <div
            className={"ptb__overview__tracking" + getStatusClass(hasExceptionStatus || ineligibleForPY || graduated)}
          >
            <TrackingRequirementTile
              isLoading={isLoading}
              trackingRequirementData={trackingRequirementData}
              legReqVolData={getLegRequiredVolume(data.legRequirementVolume)}
              bonusCurrency={data.bonusCurrency}
            />
          </div>
          <div
            className={
              "ptb__overview__eligible" +
              getVisibilityClass(data.perfBonusLevel.required) +
              getStatusClass(hasExceptionStatus)
            }
          >
            <EligibilityTile
              data={data}
              isLoading={isLoading}
              period={selectedPeriod}
              showEligibilityButton={eligible}
              handleButtonClick={handleViewEligibilityModalClick}
            />
          </div>
        </div>

        <section className={"ptb__monthlyReq" + getStatusClass(hasExceptionStatus || ineligibleForPY || graduated)}>
          <TitleSkeletonLoader isLoading={isLoading} width="18.75rem" height="1.5rem">
            <h3 className="ptb__section-title">{`${t("monthlyReqHeader", defaultContent["monthlyReqHeader"])}`}</h3>
          </TitleSkeletonLoader>
          <section className="ptb__row ptb__requirements-row" id="reqTileRow">
            <div id="legReqTile" className={"ptb__row-tile" + getVisibilityClass(true)}>
              <LegRequirementTile
                isLoading={isLoading}
                legRequirements={data.legRequirements}
                bonusCurrency={data.bonusCurrency}
                country={data.country}
                volumeNeeded={volumeNeeded}
                volumeType={volumeTypeLabel.toString()}
                newLegTenureMonths={data.newLegTenureMonths}
              ></LegRequirementTile>
            </div>
            <div className={"ptb__row-tile" + getVisibilityClass(data.perfBonusLevel.required)}>
              <VolumeRequirementTile
                title={`${t("performanceBonusReq.title", defaultContent.performanceBonusReq["title"], {
                  perfBonus: GPVTile.requiredPerfLevel,
                })}`}
                met={GPVTile.met}
                volumeNeededLabel={`${t("performanceBonusReq.tag", defaultContent.performanceBonusReq["tag"], {
                  neededGPV: GPVTile.neededAmount.actual,
                  volumeType: LeafVolumeLabel("group"),
                })}`}
                isLoading={isLoading}
                currentAmount={GPVTile.currentAmount.actual}
                targetAmount={GPVTile.targetAmount.actual}
                circleCheckmarkId={"volumeRequirementTile__gpv"}
                volumeType="group"
              />
            </div>
            <div className={"ptb__row-tile" + getVisibilityClass(displayVCS)}>
              <VcsPercentRequirementTile
                isLoading={isLoading}
                additionalNeeded={VCSTile.additionalNeeded.actual || 0}
                targetValue={VCSTile.target.actual || 0}
                shouldShowGraphics={true}
                leftValue={VCSTile.vcs.actual}
                rightValue={VCSTile.ppv.actual}
                met={VCSTile.met}
              />
            </div>
            <div className={"ptb__row-tile" + getVisibilityClass(data.personalVolume.required)}>
              <VolumeRequirementTile
                title={`${t("ppvReq.title", defaultContent.ppvReq["title"], {
                  targetPPV: PPVTile.targetAmount.formatted,
                  volumeType: LeafVolumeLabel(PPVTile.volumeType),
                })}`}
                met={PPVTile.met}
                volumeNeededLabel={`${t("ppvReq.tag", defaultContent.ppvReq["tag"], {
                  neededPPV: PPVTile.neededAmount.formatted,
                  volumeType: LeafVolumeLabel(PPVTile.volumeType),
                })}`}
                isLoading={isLoading}
                currentAmount={PPVTile.currentAmount.actual}
                targetAmount={PPVTile.targetAmount.actual}
                circleCheckmarkId={"volumeRequirementTile__ppv"}
                volumeType={PPVTile.volumeType}
              />
            </div>
          </section>
        </section>

        <section className={"ptb__bonusCalcReq" + getStatusClass(hasExceptionStatus || ineligibleForPY || graduated)}>
          <TitleSkeletonLoader isLoading={isLoading} width="18.75rem" height="1.5rem">
            <h3 id="bonusCalcTitle" className="ptb__section-title">
              {`${t("bonusCalcHeader", defaultContent["bonusCalcHeader"])}`}
            </h3>
          </TitleSkeletonLoader>
          <section className="ptb__row">
            <div className="ptb__row-tile">
              <CalcDescTile
                isLoading={isLoading}
                locale={locale}
                data={data}
                volumeNeeded={volumeNeeded}
                volumeType={LeafVolumeLabel(data.legRequirementVolume.requirement)}
              />
            </div>
          </section>
        </section>
        <EligibilityModal
          title={`${t("eligibleModal.title", defaultContent["eligibleModal"]["title"])}`}
          show={showModal}
          onCloseClick={hideModal}
          bonusHistory={getHistory()}
          awardCap={data.awardCap}
          selectedPeriod={selectedPeriod}
          id={"ptb-eligiblity-modal"}
        />
      </div>
    </main>
  );
}
