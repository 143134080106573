import { ChartDonut, ChartThemeColor } from "@patternfly/react-charts";
import "./LeafProgressHalfCircle.scss";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import defaultCommonContent from "../../../content/common";
import LeafCircleLoader from "../LeafLoadingSkeleton/LeafCircleLoader/LeafCircleLoader";

export type Props = {
  subtitle?: string;
  current: number;
  maxValue: number;
  target: number;
  leftLabel: "vcs" | "ppv";
  leftValue: number;
  rightLabel: "vcs" | "ppv";
  rightValue: number;
  isLoading?: boolean;
  labels?: Array<any>;
  animationDuration?: number;
  percent?: number;
};

/**
 * Custom Half Circle Progress Bar used for displaying users progress on inputed values (vcs, gpv, ppv, etc.).
 *
 * The height/width is NOT responsive for this leaf component and can only be used at it's imported size.
 * The target indicator is at a set point of 60% of the progress and also can not easily be adjusted.
 */
const LeafHalfCircle = ({
  subtitle,
  current,
  maxValue,
  target,
  isLoading,
  labels = [undefined, undefined],
  animationDuration = 2000,
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
  percent,
}: Props) => {
  const ref = useRef(null);
  const { t } = useTranslation(["common"]);
  const [targetIndicatorColor, setTargetIndicatorColor] = useState(`#AFAFAF`);
  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  let percentage = rightValue ? Math.max(leftValue / rightValue, 0) : 0;

  const currentDecimal = clamp(percentage, 0, 1);
  let currentPercentage = Math.trunc(percentage * 100);

  if (percent) {
    percentage = percent / 100;
    currentPercentage = Math.round(percentage * 100);
  }

  const renderLabels = (datum: any) => {
    // fix for floating-point precision errors in JavaScript
    const fixPrecision = (value: number) => Math.round(value * 100) / 100;

    if ((labels[0] !== undefined || labels[1] !== undefined) && labels.length === 2) {
      return `${datum.x}: ${fixPrecision(currentPercentage)}%`;
    }

    // when the current percentage is 0 set the tooltip value to the current percentage (value 0).
    return currentPercentage ? `${fixPrecision(datum.y)}%` : `${fixPrecision(currentPercentage)}%`;
  };
  // this controls the colors on the progress wheel
  useEffect(() => {
    if (ref && ref.current) {
      const progressBar = (ref.current as HTMLElement).querySelector(
        ".pf-v5-c-chart > svg > g > path:nth-child(1)",
      ) as HTMLElement;
      if (currentDecimal <= 0) {
        progressBar.style.fill = `#AFAFAF`;
        setTargetIndicatorColor(`#AFAFAF`);
      } else if (currentDecimal < target / 100) {
        progressBar.style.fill = `#DA7600`;
        setTargetIndicatorColor(`#DA7600`);
      } else if (currentDecimal >= target / 100) {
        progressBar.style.fill = `#107F47`;
        setTargetIndicatorColor(`#107F47`);
      }
    }
  }, [current, target, currentDecimal]);

  if (isLoading) {
    return (
      <div className="LeafProgressHalfCircle">
        <div className="LeafProgressHalfCircle__Loader">
          <LeafCircleLoader isLoading={isLoading} width="100%" height="100%" />
        </div>
      </div>
    );
  }

  return (
    <div className="leaf-circle-container" ref={ref}>
      <span className="target-indicator" style={{ color: targetIndicatorColor }}>
        ▼
      </span>
      <div className="title-container">
        <span className="center-title">{currentPercentage.toString()}%</span>
        <span className="center-subtitle">{`${t("target", defaultCommonContent["target"])}: ${target}%`}</span>
      </div>
      <div className="LeafProgressHalfCircle">
        <ChartDonut
          themeColor={ChartThemeColor.purple}
          constrainToVisibleArea
          data={[
            { x: labels[0], y: currentPercentage },
            { x: labels[1], y: maxValue - currentPercentage },
          ]}
          labels={({ datum }) => renderLabels(datum)}
          ariaDesc={subtitle}
          innerRadius={75}
          cornerRadius={3}
          padAngle={0}
          animate={{
            duration: animationDuration,
          }}
          startAngle={-90}
          endAngle={90}
        />
      </div>
      <div className="data-points-container">
        <div className="dataPoints">
          <span>{leftValue}</span>
          <span>{`${t(leftLabel, defaultCommonContent[leftLabel])}`}</span>
        </div>
        <div className="dataPoints">
          <span>{rightValue}</span>
          <span>{`${t(rightLabel, defaultCommonContent[rightLabel])}`}</span>
        </div>
      </div>
    </div>
  );
};

export default LeafHalfCircle;
